import React from 'react';
import moment from 'moment';

// import components
import IntakeList from './../../components/intake/intakeList';
import { IColumn, Stack, Text } from '@fluentui/react';

// import fabric ui

// local interfaces
interface IKEPPageProps { }
interface IKEPPageState { }

export default class KEPPage extends React.Component<IKEPPageProps, IKEPPageState> {
    private listColumns: IColumn[] = [
        {
            key: 'intakeName',
            name: 'Nama Tahun Pelajaran',
            fieldName: 'name',
            minWidth: 200,
            data: 'string',
            isPadded: true
        },
        {
            key: 'startDate',
            name: 'Tanggal Mulai',
            fieldName: 'startDate',
            minWidth: 0,
            maxWidth: 150,
            onRender: (item) => {
                return <Text variant="small">{moment(item.startDate).format("DD/MM/YYYY")}</Text>;
            },
            isPadded: true
        },
        {
            key: 'endDate',
            name: 'Tanggal Berakhir',
            fieldName: 'endDate',
            minWidth: 0,
            maxWidth: 150,
            onRender: (item) => {
                return <Text variant="small">{moment(item.endDate).format("DD/MM/YYYY")}</Text>;
            },
            isPadded: true
        },
        {
            key: 'active',
            name: 'Status',
            fieldName: 'active',
            minWidth: 0,
            maxWidth: 100,
            onRender: (item) => {
                return <Text variant="small">{item.active ? "Aktif" : "Tidak aktif"}</Text>;
            },
            isPadded: true
        }
    ];

    constructor(props: IKEPPageProps) {
        super(props);
    }

    public render() {
        return (
            <Stack styles={{ root: { padding: 20 } }}>
                <Stack.Item>
                    <h2>Tahun Pelajaran</h2>
                </Stack.Item>
                <Stack.Item>
                    <IntakeList listColumns={this.listColumns} includeCommandBar={true} hasActionButton={true} searchBy={["name"]} sortable={["name", "startDate", "endDate", "active"]} filterable={[]} />
                </Stack.Item>
            </Stack>
        );
    }
}
