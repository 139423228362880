import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/login/login';
import LogoutPage from './pages/logout/logout';
import HomePage from './pages/home/home';
import AuthenticationService from './services/authentication';
import UserService from './services/user';
import './styles/general.scss';
import { Spinner, SpinnerSize } from '@fluentui/react';

const App = () => {
  const [checkingCredential, setCheckingCredential] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        const authenticationService = new AuthenticationService();
        await authenticationService.checkLogin();
        const userService = new UserService();
        const currentUser = await userService.getCurrentUser();
        window.getCurrentUser = () => currentUser;
        setLoggedIn(true);
      } catch (e) {
        if (window.location.pathname !== '/login' && window.location.pathname !== '/logout') {
          window.location.href = '/login';
        }
      } finally {
        setCheckingCredential(false);
      }
    };

    authenticateUser();
  }, []);

  return (
    checkingCredential ?
      <Spinner label="Harap tunggu ..." labelPosition="bottom" size={SpinnerSize.large} style={{ marginTop: 20 }} />
      :
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="*" element={loggedIn ? <HomePage /> : <LoginPage />} />
        </Routes>
      </BrowserRouter>
  );
};

export default App;
