import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GeneralService from './../../services/general';
import homePageStyles from './home.module.scss';
import Menubar from './../../components/menubar/menubar';
import DashboardPage from './../dashboard/dashboard';
import KEPPage from './../kep/kep';
import ClassPage from './../class/class';
import ModulePage from './../module/module';
import LessonPage from './../lesson/lesson';
import UserPage from './../user/user';
import EventPage from './../event/event';
import RandomisePage from './../randomise/randomise';
import TeacherSchedulePage from './../teacherSchedule/teacherSchedule';
import SchedulePage from './../schedule/schedule';
import MyAccount from './../myaccount/myAccount';
import Intake from './../intake/intake';
import { Stack } from '@fluentui/react';

const HomePage = () => {
    const [checkingCredential, setCheckingCredential] = useState(true);

    useEffect(() => {
        const checkCredential = async () => {
            // Simulate asynchronous credential check (replace with your actual logic)
            await new Promise((resolve) => setTimeout(resolve, 1000));
            setCheckingCredential(false);
        };

        checkCredential();
    }, []);

    return (
        <Stack className={homePageStyles.home}>
            <Stack.Item>
                <Menubar />
            </Stack.Item>
            <Stack.Item>
                <Routes>
                    <Route path="/beranda" element={<Stack.Item className={homePageStyles.dashboardContainer}><DashboardPage /></Stack.Item>} />
                    <Route path="/kep" element={<Stack.Item className={homePageStyles.contentContainer}><KEPPage /></Stack.Item>} />
                    <Route path="/kelas" element={<Stack.Item className={homePageStyles.contentContainer}><ClassPage /></Stack.Item>} />
                    <Route path="/modul" element={<Stack.Item className={homePageStyles.contentContainer}><ModulePage /></Stack.Item>} />
                    <Route path="/pelajaran" element={<Stack.Item className={homePageStyles.contentContainer}><LessonPage /></Stack.Item>} />
                    <Route path="/pengguna" element={<Stack.Item className={homePageStyles.contentContainer}><UserPage /></Stack.Item>} />
                    <Route path="/acara" element={<Stack.Item className={homePageStyles.contentContainer}><EventPage /></Stack.Item>} />
                    <Route path="/jadwal" element={<Stack.Item className={homePageStyles.contentContainer}><SchedulePage /></Stack.Item>} />
                    <Route path="/pengacakan" element={(GeneralService.isCurrentUserAdmin() || GeneralService.isCurrentUserSecretary()) ? <Stack.Item className={homePageStyles.contentContainer}><RandomisePage /></Stack.Item> : <></>} />
                    <Route path="/jadwalGuru" element={<Stack.Item className={homePageStyles.contentContainer}><TeacherSchedulePage /></Stack.Item>} />
                    <Route path="/akun" element={<Stack.Item className={homePageStyles.contentContainer}><MyAccount /></Stack.Item>} />
                    <Route path="/tahunPelajaran" element={<Stack.Item className={homePageStyles.contentContainer}><Intake /></Stack.Item>} />
                </Routes>
            </Stack.Item>
        </Stack>
    );
};

export default HomePage;
