import React from 'react';

// import components
import LessonList from './../../components/lesson/lessonList';
import { IColumn, Stack, Text } from '@fluentui/react';

// import fabric ui


// local interfaces
interface ILessonPageProps { }
interface ILessonPageState { }

export default class LessonPage extends React.Component<ILessonPageProps, ILessonPageState> {
    private listColumns: IColumn[] = [
        {
            key: 'lessonName',
            name: 'Nama Pelajaran',
            fieldName: 'name',
            minWidth: 300,
            data: 'string',
            isPadded: true
        },
        {
            key: 'chapterNumber',
            name: 'No. Bab',
            fieldName: 'chapter',
            minWidth: 0,
            data: 'number',
            isPadded: true
        },
        {
            key: 'moduleName',
            name: 'Nama Modul',
            fieldName: 'module',
            minWidth: 100,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Text>{item.module.name}</Text>;
            }
        },
        {
            key: 'className',
            name: 'Nama Kelas',
            fieldName: 'class',
            minWidth: 100,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Text>{item.module.class.name}</Text>;
            }
        }
    ]

    constructor(props: ILessonPageProps) {
        super(props);
    }

    public render() {
        return (
            <Stack styles={{ root: { padding: 20 } }}>
                <Stack.Item>
                    <h2>Pelajaran</h2>
                </Stack.Item>
                <Stack.Item>
                    <LessonList listColumns={this.listColumns} includeCommandBar={true} hasActionButton={true} searchBy={["name"]} sortable={["name"]} filterable={["name"]} />
                </Stack.Item>
            </Stack>
        );
    }
}
