import React from 'react';
import ReactDOM from 'react-dom/client';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as fas from '@fortawesome/pro-light-svg-icons';
import { createTheme, loadTheme, registerIcons } from '@fluentui/react';

loadTheme(createTheme({
  defaultFontStyle: { fontWeight: 'normal' },
}));


registerIcons({
  icons: {
    'Home': <Icon icon={fas.faHome} />,
    'Teacher': <Icon icon={fas.faChalkboardTeacher} />,
    'School': <Icon icon={fas.faSchool} />,
    'Add': <Icon icon={fas.faPlus} />,
    'Search': <Icon icon={fas.faSearch} />,
    'ChevronUp': <Icon icon={fas.faChevronUp} />,
    'ChevronDown': <Icon icon={fas.faChevronDown} />,
    'ChevronUpSmall': <Icon icon={fas.faChevronUp} />,
    'ChevronDownSmall': <Icon icon={fas.faChevronDown} />,
    'CheckMark': <Icon icon={fas.faCheck} />,
    'Delete': <Icon icon={fas.faTrashAlt} />,
    'Edit': <Icon icon={fas.faEdit} />,
    'Close': <Icon icon={fas.faTimes} />,
    'ChromeClose': <Icon icon={fas.faTimes} />,
    'Cancel': <Icon icon={fas.faTimes} />,
    'Clear': <Icon icon={fas.faTimes} />,
    'Completed': <Icon icon={fas.faCheckCircle} />,
    'GraduationCap': <Icon icon={fas.faGraduationCap} />,
    'Tasks': <Icon icon={fas.faTasks} />,
    'CaretSolidUp': <Icon icon={fas.faCaretUp} />,
    'CaretSolidDown': <Icon icon={fas.faCaretDown} />,
    'Database': <Icon icon={fas.faDatabase} />,
    'Modules': <Icon icon={fas.faCubes} />,
    'Book': <Icon icon={fas.faBook} />,
    'User': <Icon icon={fas.faUser} />,
    'Sort': <Icon icon={fas.faSort} />,
    'SortUp': <Icon icon={fas.faSortAlphaDown} />,
    'SortDown': <Icon icon={fas.faSortAlphaUp} />,
    'Filter': <Icon icon={fas.faFilter} />,
    'StatusCircleCheckmark': <Icon icon={fas.faCheckCircle} />,
    'Calendar': <Icon icon={fas.faCalendarAlt} />,
    'CalendarCheck': <Icon icon={fas.faCalendarCheck} />,
    'CalendarEmpty': <Icon icon={fas.faCalendar} />,
    'Dice': <Icon icon={fas.faDiceTwo} />,
    'Compress': <Icon icon={fas.faCompress} />,
    'CompressAlt': <Icon icon={fas.faCompressAlt} />,
    'Print': <Icon icon={fas.faPrint} />,
    'Save': <Icon icon={fas.faSave} />,
    'More': <Icon icon={fas.faBars} />,
    'Warning': <Icon icon={fas.faExclamationCircle} />,
    'Error': <Icon icon={fas.faTimesCircle} />,
    'ErrorBadge': <Icon icon={fas.faTimesCircle} />,
    'SignOut': <Icon icon={fas.faSignOutAlt} />,
    'Refresh': <Icon icon={fas.faSyncAlt} />,
    'Up': <Icon icon={fas.faChevronUp} />,
    'Down': <Icon icon={fas.faChevronDown} />,
    'Info': <Icon icon={fas.faExclamationCircle} />,
    'Download': <Icon icon={fas.faDownload} />
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
