import React from 'react';
import _ from 'underscore';

// import interfaces
import { IListPanelClosed } from './../../props/general';

// import services
import UserService from './../../services/user';
import { MessageBarType, PrimaryButton, Panel, PanelType, Stack, Spinner, SpinnerSize, MessageBar, TextField } from '@fluentui/react';

// import fabric ui


// local interfaces

interface IResetPasswordPanelProps {
    id: string;
    onPanelClosed(props?: IListPanelClosed): void;
}

interface IResetPasswordPanelState {
    newPassword: string;
    loading: boolean;
    success?: boolean;
    messageBar?: { text: string; type: MessageBarType };
}

export default class UpdatePasswordPanel extends React.Component<IResetPasswordPanelProps, IResetPasswordPanelState> {
    private userService: UserService = new UserService();

    constructor(props: IResetPasswordPanelProps) {
        super(props);

        this.state = {
            loading: true,
            newPassword: ""
        }
    }

    public async componentWillMount() {
        try {
            let newPassword = await this.userService.resetPassword(this.props.id);
            this.setState({
                newPassword,
                loading: false,
                success: true,
                messageBar: {
                    text: "Password berhasil diubah. Simpan password dibawah di tempat aman. Setelah panel ini ditutup, password akan dihapus.",
                    type: MessageBarType.success
                }
            });
        } catch (e) {
            this.setState({
                loading: false,
                success: false,
                messageBar: {
                    text: "Gagal mengubah password. Silahkan tutup panel ini dan coba beberapa saat lagi.",
                    type: MessageBarType.error
                }
            });
        }
    }

    private renderPanelFooter = () => {
        return <PrimaryButton disabled={this.state.loading} onClick={() => { this.props.onPanelClosed() }}>Tutup</PrimaryButton>
    }

    public render() {
        return (
            <Panel
                type={PanelType.medium}
                headerText="Ubah Password"
                isOpen={true}
                closeButtonAriaLabel="Tutup"
                onRenderFooterContent={this.renderPanelFooter}
                isFooterAtBottom={true}>
                <Stack tokens={{ childrenGap: 15 }}>
                    {this.state.loading ? <Spinner size={SpinnerSize.medium} label="Mengubah password ..." labelPosition={"right"} /> : null}
                    {
                        this.state.messageBar ? (
                            <Stack.Item>
                                <MessageBar messageBarType={this.state.messageBar.type} isMultiline={true}>
                                    {this.state.messageBar.text}
                                </MessageBar>
                            </Stack.Item>
                        ) : null
                    }
                    {!this.state.loading && this.state.success ? <TextField label="Password baru" value={this.state.newPassword} disabled={true} /> : null}
                </Stack>
            </Panel>
        );
    }
}
