import React from 'react';

// import services
import UserService from './../../services/user';

// import interface
import { IUserDataProps } from './../../props/data';

// import fabric ui


// import pivots
import Profile from './pivots/profile';
import ChangePassword from './pivots/changePassword';
import { Stack, Spinner, SpinnerSize, Pivot, PivotItem } from '@fluentui/react';

// local interfaces
interface IMyAccountPageProps { }
interface IMyAccountPageState {
    myAccount: IUserDataProps;
    loaded?: boolean;
}

declare var window: any;
export default class MyAccountPage extends React.Component<IMyAccountPageProps, IMyAccountPageState> {
    private userService = new UserService();

    constructor(props: IMyAccountPageProps) {
        super(props);
    }

    public async componentWillMount() {
        let myAccount: IUserDataProps = window.getCurrentUser();
        this.setState({ myAccount, loaded: true });
    }

    public render() {
        return (
            <Stack styles={{ root: { padding: 20 } }}>
                <Stack.Item>
                    <h2>Kelola Akun</h2>
                </Stack.Item>
                {!this.state.loaded ? <Spinner label="Mempersiapkan data ..." labelPosition="bottom" size={SpinnerSize.large} /> : null}
                {this.state.loaded ? <Stack.Item>
                    <Pivot aria-label="Basic Pivot Example">
                        <PivotItem headerText="Profil">
                            <Profile data={this.state.myAccount} />
                        </PivotItem>
                        <PivotItem headerText="Ganti Password">
                            <ChangePassword data={this.state.myAccount} />
                        </PivotItem>
                    </Pivot>
                </Stack.Item> : null}
            </Stack>
        );
    }
}
