import React from 'react';

// import styles
import kepInternalStyles from './../../styles/kepinternal.module.scss';

// import interfaces
import { IUserDataProps, TUserRoleType } from './../../props/data';
import { IListPanelClosed } from './../../props/general';

// import services
import UserService from './../../services/user';

// import components
import UserPropsPanel from './userPropsPanel';
import ResetPasswordPanel from './resetPasswordPanel';
import { Text, IColumn, ICommandBarItemProps, MessageBarType, SearchBox, IconButton, Stack, CommandBar, MessageBar, Spinner, SpinnerSize, DetailsList, SelectionMode, DetailsListLayoutMode } from '@fluentui/react';

// import fabric ui


// local interfaces
interface ITeacherListProps {
    role: TUserRoleType;
    listColumns: IColumn[];
    includeCommandBar: boolean;
    hasActionButton: boolean;
    allowUpdate?: boolean;
    allowDelete?: boolean;
    allowResetPassword?: boolean;
    allowCreate?: boolean;
}

interface ITeacherListState {
    commandBar: {
        leftItems?: ICommandBarItemProps[];
        rightItems?: ICommandBarItemProps[];
    };
    messageBar?: { text: string; type: MessageBarType };
    data: IUserDataProps[];
    selectedData?: IUserDataProps[];
    loading?: boolean;
    listColumns: IColumn[];
    components: {
        TeacherPropsPanelOpened?: boolean;
        ResetUserPanelOpened?: boolean;
    };
}

export default class TeacherList extends React.Component<ITeacherListProps, ITeacherListState> {
    private userService: UserService = new UserService();

    constructor(props: ITeacherListProps) {
        super(props);

        this.state = {
            commandBar: {},
            data: [],
            loading: true,
            listColumns: [],
            components: {}
        }
    }

    public async componentWillMount() {
        this.init();
    }

    private async init() {
        try {
            let leftItems: ICommandBarItemProps[] = [
                {
                    key: 'searchUser',
                    onRender: () => {
                        return (
                            <SearchBox className={kepInternalStyles.searchBox} placeholder="Cari Modul" />
                        );
                    }
                }
            ];

            if (this.props.allowCreate) {
                leftItems = [...leftItems, ...[
                    {
                        key: 'newUser',
                        text: 'Tambah pengguna baru',
                        iconProps: { iconName: 'Add' },
                        onClick: () => {
                            this.setState({ selectedData: undefined, components: { TeacherPropsPanelOpened: true } });
                        }
                    }
                ]
                ];
            }

            const data = await this.userService.getAll(this.props.role);

            const listColumns: IColumn[] = this.props.listColumns;
            if (this.props.hasActionButton && (this.props.allowDelete || this.props.allowResetPassword || this.props.allowUpdate)) {
                listColumns.splice(1, 0, {
                    key: 'actionButtons',
                    name: '',
                    minWidth: 20,
                    maxWidth: 20,
                    isIconOnly: true,
                    className: kepInternalStyles.listActionButtons,
                    onRender: (item?: any) => {
                        const menuItems = [];
                        if (this.props.allowUpdate) {
                            menuItems.push({
                                key: 'updateUser',
                                text: 'Ubah data',
                                iconProps: { iconName: 'Edit' },
                                onClick: () => {
                                    this.setState({
                                        selectedData: [item as IUserDataProps],
                                        components: { TeacherPropsPanelOpened: true }
                                    });
                                }
                            });
                        }
                        if (this.props.allowDelete) {
                            menuItems.push({
                                key: 'deleteUser',
                                text: 'Hapus pengguna',
                                iconProps: { iconName: 'Delete' },
                                onClick: () => {
                                    if (window.confirm(`Apakah anda yakin ingin Menghapus pengguna ${item.name}?`)) {
                                        this._deleteUser(item)
                                    }
                                }
                            });
                        }
                        if (this.props.allowResetPassword) {
                            menuItems.push({
                                key: 'resetUserPassword',
                                text: 'Reset password',
                                iconProps: { iconName: 'Refresh' },
                                onClick: () => {
                                    if (window.confirm(`Apakah anda yakin ingin mengubah password pengguna ${item.name}?`)) {
                                        this.setState({ selectedData: [item as IUserDataProps], components: { ResetUserPanelOpened: true } });
                                    }
                                }
                            });
                        }
                        return (
                            <IconButton
                                menuProps={{ items: menuItems }}
                                title="Tombol aksi"
                                ariaLabel="Tombol aksi" />
                        );
                    }
                });
            }

            this.setState({
                commandBar: { leftItems },
                listColumns,
                data,
                loading: false
            });
        } catch (error) {

        }
    }

    private _deleteUser = async (user: IUserDataProps) => {
        try {
            await this.userService.delete(user);
            this._onRefreshData()
        } catch (error) {

        }
    }

    private async _onRefreshData() {
        this.setState({ loading: true });
        try {
            const data = await this.userService.getAll(this.props.role);
            this.setState({ data, loading: false });
        } catch (error) {

        }
    }

    private _onTeacherPropsPanelClosed = async (props?: IListPanelClosed): Promise<void> => {
        this.setState({ components: {} });
        if (props && props.refreshData) { this._onRefreshData(); }
        if (props && props.messageBar) { this.setState({ messageBar: props.messageBar }); }
    }

    public render() {
        return (
            <Stack tokens={{ childrenGap: 10 }}>
                {
                    this.props.includeCommandBar ? (
                        <Stack.Item>
                            <CommandBar
                                items={this.state.commandBar.leftItems || []}
                                farItems={this.state.commandBar.rightItems || []}
                                ariaLabel="Gunakan panah kiri dan kanan untuk berpindah tombol"
                                className={kepInternalStyles.commandBar}
                                styles={{ root: { padding: 0 } }} />
                        </Stack.Item>
                    ) : null
                }
                {
                    this.state.messageBar ? (
                        <Stack.Item>
                            <MessageBar messageBarType={this.state.messageBar.type} isMultiline={true} onDismiss={() => { this.setState({ messageBar: undefined }) }}>
                                {this.state.messageBar.text}
                            </MessageBar>
                        </Stack.Item>
                    ) : null
                }
                <Stack.Item>
                    {
                        this.state.loading ? (
                            <Spinner label={`Memuat semua  ${this.props.role} ...`} labelPosition="bottom" size={SpinnerSize.large} />
                        ) : null
                    }
                    {
                        !this.state.loading ? (
                            <DetailsList
                                items={this.state.data}
                                compact={false}
                                columns={this.state.listColumns}
                                selectionMode={SelectionMode.none}
                                setKey="none"
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true} />
                        ) : null
                    }
                    {!this.state.loading && this.state.data.length < 1 ? <Text style={{ padding: 10 }}>Pengguna tidak dapat ditemukan. Tekan tombol "Tambah pengguna baru" untuk membuat pengguna baru.</Text> : null}
                    {this.state.components.TeacherPropsPanelOpened ? <UserPropsPanel data={this.state.selectedData ? this.state.selectedData[0] : undefined} onPanelClosed={this._onTeacherPropsPanelClosed} /> : null}
                    {this.state.components.ResetUserPanelOpened && this.state.selectedData && this.state.selectedData[0] ? <ResetPasswordPanel id={this.state.selectedData[0].id} onPanelClosed={() => { this.setState({ components: {} }) }} /> : null}
                </Stack.Item>
            </Stack>
        );
    }
}
