import { v4 as uuid } from 'uuid';
import Request from './request';

// import interfaces
import { ILessonDataProps, ILessonDataType, IModuleDataProps, IClassDataProps, IUserDataProps, ILessonTeachersDataProps } from './../props/data';

// import services
import ModuleService from './../services/module';
import ClassService from './../services/class';

export default class LessonService {
    private moduleService: ModuleService = new ModuleService();
    private classService: ClassService = new ClassService();

    public async getById(id: string): Promise<ILessonDataProps | undefined> {
        try {
            let results: any = await Request.get(`/api/lesson/${id}`);
            let relatedLesson: ILessonDataProps = results.data.data;

            // get related module
            relatedLesson.module = await this.moduleService.getById(relatedLesson.moduleId);

            return relatedLesson;
        } catch (e) {
            throw (e);
        }
    }

    public async getAll(): Promise<ILessonDataProps[]> {
        try {
            // get all modules
            let results: any = await Request.get("/api/lesson");
            let lessons: ILessonDataProps[] = results.data.data;

            // get all modules
            let modules: IModuleDataProps[] = await this.moduleService.getAll();

            lessons = lessons.map((lesson) => {
                // get related keps
                lesson.module = modules.find((module) => { return module.id === lesson.moduleId; });
                return lesson;
            });
            return lessons;
        } catch (e) {
            throw (e);
        }
    }

    public async create(data: ILessonDataProps): Promise<ILessonDataProps> {
        try {
            data.id = uuid();
            data.chapter = parseInt(data.chapter + "");
            data.duration = parseInt(data.duration + "");
            let result: any = await Request.post("/api/lesson", data);
            return result.data.data as ILessonDataProps;
        } catch (e) {
            throw (e);
        }
    }

    public async update(id: string, data: ILessonDataProps) {
        try {
            await Request.patch(`/api/lesson/${id}`, data);
            return data;
        } catch (e) {
            throw (e);
        }
    }

    public async delete(data: ILessonDataProps) {
        try {
            await Request.delete(`/api/lesson/${data.id}`);
            return data;
        } catch (e) {
            throw (e);
        }
    }

    public async getAllUniqueFieldValues(fieldName: string): Promise<{ key: string; text: string }[]> {
        if (fieldName === "module") {
            let modules: IModuleDataProps[] = await this.moduleService.getAll();
            return modules.map((m) => { return { key: m.id, text: m.name }; });
        } else if (fieldName === "class") {
            let classes: IClassDataProps[] = await this.classService.getAll();
            return classes.map((c) => { return { key: c.id, text: c.name }; });
        } else {
            let results: any = [];
            /*lessonData.forEach((d) => {
                if (results.indexOf(d[fieldName as ILessonDataType]) < 0) {
                    results.push({
                        key: d[fieldName as ILessonDataType],
                        text: d[fieldName as ILessonDataType]
                    });
                }
            });*/

            return results.filter(Boolean);
        }
    }

    public async getTeachersByLessonId(lessonId: string): Promise<ILessonTeachersDataProps[]> {
        try {
            let results: any = await Request.get(`/api/lesson/${lessonId}/teachers`);
            let data: ILessonTeachersDataProps[] = results.data.data;
            return data.map((d) => { d.level = 1; d.priority = 1; return d; });
        } catch (e) {
            throw (e);
        }
    }
}