import React from 'react';

// import services
import UserService from './../../../services/user';

// import interfaces
import { IUserDataProps } from '../../../props/data';
import { MessageBarType, Stack, MessageBar, TextField, Spinner, SpinnerSize, PrimaryButton } from '@fluentui/react';

// import fabric ui


// local interfaces
interface IChangePasswordPivotProps {
    data: IUserDataProps;
}

interface IChangePasswordPivotState {
    updating?: boolean;
    oldPassword: string;
    newPassword: string;
    repeatPassword: string;
    oldPasswordError?: string;
    newPasswordError?: string;
    repeatPasswordError?: string;
    messageBar?: { text: string; type: MessageBarType };
}

export default class ProfilePivot extends React.Component<IChangePasswordPivotProps, IChangePasswordPivotState> {
    private userService: UserService = new UserService();

    constructor(props: IChangePasswordPivotProps) {
        super(props);
        this.state = {
            oldPassword: "",
            newPassword: "",
            repeatPassword: ""
        }
    }

    private _onOldPasswordChanged = (evt?: any, value?: string) => {
        this.setState({ oldPassword: value || "", oldPasswordError: value && value.length > 0 ? undefined : "Password lama tidak boleh kosong" });
    }

    private _onNewPasswordChanged = (evt?: any, value?: string) => {
        let repeatPasswordError = this.state.repeatPassword !== value ? "Password baru tidak sama" : undefined;
        this.setState({ newPassword: value || "", repeatPasswordError, newPasswordError: value && value.length > 0 ? undefined : "Password baru tidak boleh kosong" });
    }

    private _onRepeatPasswordChanged = (evt?: any, value?: string) => {
        let repeatPasswordError = this.state.newPassword !== value ? "Password baru tidak sama" : undefined;
        this.setState({ repeatPassword: value || "", repeatPasswordError });
    }

    private async _onSave() {
        if (!this.state.newPasswordError && !this.state.oldPasswordError && !this.state.repeatPasswordError
            && this.state.newPassword.length > 0 && this.state.oldPassword.length > 0 && this.state.repeatPassword.length > 0) {
            try {
                this.setState({ updating: true, messageBar: undefined });
                await this.userService.updatePassword(this.state.oldPassword, this.state.newPassword);
                this.setState({
                    newPassword: "",
                    oldPassword: "",
                    repeatPassword: "",
                    updating: false,
                    messageBar: {
                        text: "Password pengguna berhasil diubah",
                        type: MessageBarType.success
                    }
                });
            } catch (e) {
                this.setState({
                    oldPassword: "",
                    newPassword: "",
                    repeatPassword: "",
                    updating: false,
                    messageBar: {
                        text: "Gagal mengganti password. Pesan: " + e,
                        type: MessageBarType.error
                    }
                });
            }
        }
    }

    public render() {
        return (
            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
                {
                    this.state.messageBar ? (
                        <Stack.Item>
                            <MessageBar messageBarType={this.state.messageBar.type} isMultiline={true} onDismiss={() => { this.setState({ messageBar: undefined }) }}>
                                {this.state.messageBar.text}
                            </MessageBar>
                        </Stack.Item>
                    ) : null
                }
                <TextField disabled={this.state.updating} required={true} value={this.state.oldPassword} label="Password lama" type={"password"} onChange={this._onOldPasswordChanged} errorMessage={this.state.oldPasswordError} />
                <TextField disabled={this.state.updating} required={true} value={this.state.newPassword} label="Password baru" type={"password"} onChange={this._onNewPasswordChanged} errorMessage={this.state.newPasswordError} />
                <TextField disabled={this.state.updating} required={true} value={this.state.repeatPassword} label="Ulangi password baru" type={"password"} onChange={this._onRepeatPasswordChanged} errorMessage={this.state.repeatPasswordError} />
                <Stack.Item>
                    {
                        this.state.updating ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.small} label={"Mengganti password ..."} labelPosition={"right"} /></Stack> : null
                    }
                    {
                        !this.state.updating ? <PrimaryButton disabled={this.state.newPassword.length < 1 || this.state.oldPassword.length < 1 || this.state.repeatPassword.length < 1 || this.state.repeatPasswordError !== undefined} onClick={() => { this._onSave() }}>Ubah Password</PrimaryButton> : null
                    }
                </Stack.Item>
            </Stack>
        );
    }
}
