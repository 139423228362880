import axios from 'axios';
import Config from '../config';
import Cookies from 'js-cookie';

export default class LoginService {
    public async logout() {
        try {
            let token = Cookies.get('token');
            await axios.get(Config.hostname + '/api/auth/logout', {
                headers: {
                    authorization: "Bearer " + token
                }
            });
            //window.localStorage.clear();
            Cookies.remove('token');
            delete window.getCurrentUser;
            return true;
        } catch (e: any) {
            throw (e.response.data.message);
        }
    }

    public async login(email: string, password: string) {
        try {
            let result = await axios.post(Config.hostname + '/api/auth/login', {
                email,
                password
            });
            Cookies.set('token', result.data.access_token);
            return true;
        } catch (e: any) {
            throw (e.response.data.message);
        }
        /*await new Promise((resolve) => {setTimeout(() => {return resolve();}, 2000);});
        let currentAccount = AccountData.find((account) => {
            return account.email === username && account.password === password;
        });*/
    }

    public async checkLogin() {
        try {
            let token = Cookies.get('token');
            if (token) {
                await axios.get(Config.hostname + '/api/auth/login', {
                    headers: {
                        authorization: "Bearer " + token
                    }
                });
                return true;
            } else {
                throw ("Token tidak dapat ditemukan.");
            }
        } catch (e: any) {
            throw (e.response.data.message);
        }
    }
}