import React from 'react';
import Cities from './../../../props/city';

// import services
import UserService from './../../../services/user';

// import interfaces
import { IUserDataProps } from '../../../props/data';
import { MessageBarType, IDropdownOption, Stack, MessageBar, TextField, Dropdown, Spinner, SpinnerSize, PrimaryButton } from '@fluentui/react';

// import fabric ui


// local interfaces
interface IProfilePivotProps {
    data: IUserDataProps;
}
interface IProfilePivotState {
    updating?: boolean;
    data: IUserDataProps;
    errorName?: string;
    errorEmail?: string;
    errorCity?: string;
    errorPhoneNumber?: string;
    messageBar?: { text: string; type: MessageBarType };
}

export default class ProfilePivot extends React.Component<IProfilePivotProps, IProfilePivotState> {
    private userService: UserService = new UserService();

    constructor(props: IProfilePivotProps) {
        super(props);
        this.state = {
            data: this.props.data
        }
    }

    private _onNameChanged = (evt?: any, value?: string) => {
        let data = this.state.data;
        data.name = value || "";
        this.setState({ data });
        this.setState({ data, errorName: (value || "").length > 0 ? undefined : "Nama tidak boleh kosong." });
    }

    private _onEmailChanged = (evt?: any, value?: string) => {
        let data = this.state.data;
        const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        let errorEmail = (value || "").length > 0 ? undefined : "Email tidak boleh kosong.";
        errorEmail = !errorEmail && !emailRegex.test(value || "") ? "Alamat email tidak valid" : errorEmail;
        data.email = value || "";
        this.setState({ data, errorEmail });
    }

    private _onAddressChanged = (evt?: any, value?: string) => {
        let data = this.state.data;
        data.address = value;
        this.setState({ data });
    }

    private _onPhoneNumberChanged = (evt?: any, value?: string) => {
        let data = this.state.data;
        const phoneRegex = /\d{10}/;
        let errorPhoneNumber = value && value.length > 0 && !phoneRegex.test(value) ? "Nomor telepon tidak valid" : undefined;
        data.phoneNumber = value;
        this.setState({ data, errorPhoneNumber });
    }

    private _onCityChanged = (evt?: any, option?: IDropdownOption) => {
        let data = this.state.data;
        data.city = option ? option.key as string : "";
        this.setState({ data, errorEmail: (option && (option.key as string).length > 0 ? option.key : undefined) ? undefined : "Kota tidak boleh kosong." });
    }

    private _onSave = async () => {
        let { data, errorName, errorEmail, errorCity, errorPhoneNumber } = this.state;
        if (data.name.length > 0 && data.city.length > 0 && data.email.length > 0 && !errorName && !errorCity && !errorEmail && !errorPhoneNumber) {
            try {
                this.setState({ updating: true, messageBar: undefined });
                await this.userService.updateProfile(data.id, {
                    name: data.name,
                    address: data.address,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                    city: data.city
                });
                this.setState({
                    updating: false,
                    messageBar: {
                        text: "Profil pengguna berhasil diubah",
                        type: MessageBarType.success
                    }
                });
            } catch (e) {
                this.setState({
                    updating: false,
                    messageBar: {
                        text: "Gagal mengubah profil. Pesan: " + e,
                        type: MessageBarType.error
                    }
                });
            }
        }
    }

    public render() {
        return (
            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
                {
                    this.state.messageBar ? (
                        <Stack.Item>
                            <MessageBar messageBarType={this.state.messageBar.type} isMultiline={true} onDismiss={() => { this.setState({ messageBar: undefined }) }}>
                                {this.state.messageBar.text}
                            </MessageBar>
                        </Stack.Item>
                    ) : null
                }
                <TextField errorMessage={this.state.errorName} disabled={this.state.updating} required={true} value={this.state.data.name} label="Nama lengkap" onChange={this._onNameChanged} />
                <TextField errorMessage={this.state.errorEmail} disabled={this.state.updating} required={true} value={this.state.data.email} label="Email" onChange={this._onEmailChanged} />
                <TextField disabled={this.state.updating} multiline={true} rows={5} value={this.state.data.address} label="Alamat lengkap" onChange={this._onAddressChanged} />
                <Dropdown errorMessage={this.state.errorCity} disabled={this.state.updating} required={true} options={Cities} selectedKey={this.state.data.city} label={"Kota"} onChange={this._onCityChanged} />
                <TextField description={"Kode kota harus disertakan juga"} errorMessage={this.state.errorPhoneNumber} disabled={this.state.updating} value={this.state.data.phoneNumber} label="Nomor Telepon" onChange={this._onPhoneNumberChanged} />
                <Stack.Item>
                    {
                        this.state.updating ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.small} label={"Mengganti password ..."} labelPosition={"right"} /></Stack> : null
                    }
                    {
                        !this.state.updating ? <PrimaryButton disabled={this.state.data.name.length < 1 || this.state.data.city.length < 1 || this.state.data.email.length < 1 || this.state.errorEmail !== undefined || this.state.errorName !== undefined || this.state.errorPhoneNumber !== undefined} onClick={() => { this._onSave() }}>Ubah Profil</PrimaryButton> : null
                    }
                </Stack.Item>
            </Stack>
        );
    }
}
